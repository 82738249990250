<template>
  <section class="entity-form-execute">
    <div
      v-if="checkPerimission('UPDATE')"
      style="text-align: center"
      class="mt-2"
    >
      <h2 style="color: red">Don't have access</h2>
      <el-link @click="backToEntityInfoList" :underline="false">Back</el-link>
    </div>
    <div
      v-else
      v-loading="loading"
      v-loading.fullscreen.lock="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div v-if="getAllSteps && getAllSteps.length">
        <h3 class="page-title d-flex align-center mt-2">
          <el-popconfirm
            v-if="getIsMobile"
            title="Are you sure you want to go back?"
            @confirm="backToEntityInfoList()"
            confirm-button-text="Yes"
            cancel-button-text="No"
          >
            <el-button slot="reference" icon="el-icon-back" size="mini"
              >Back</el-button
            >
          </el-popconfirm>
          <!-- <el-button
            v-if="getIsMobile"
            size="mini"
            icon="el-icon-back"
            @click="backToEntityInfoList()"
            >Back</el-button
          > -->
        </h3>
        <el-row :gutter="12" class="statsSty">
          <el-col :xl="2" :lg="2" :mg="2" :sm="2" :xs="2">
            <!-- <el-button
              v-if="!getIsMobile"
              size="mini"
              :style="getIsMobile ? '' : 'margin-top: 21px'"
              icon="el-icon-back"
              @click="backToEntityInfoList()"
              >Back</el-button
            > -->
            <el-popconfirm
              v-if="!getIsMobile"
              title="Are you sure you want to go back?"
              @confirm="backToEntityInfoList()"
              confirm-button-text="Yes"
              cancel-button-text="No"
            >
              <el-button
                slot="reference"
                size="mini"
                icon="el-icon-back"
                :style="getIsMobile ? '' : 'margin-top: 21px'"
                >Back</el-button
              >
            </el-popconfirm>
          </el-col>
          <el-col
            v-if="!getIsMobile"
            :xl="{ span: 8 }"
            :lg="{ span: 8 }"
            :md="{ span: 24 }"
            :sm="{ span: 6 }"
            :xs="{ span: 6 }"
          >
            <el-card shadow="never">
              <img
                class="imgsty"
                src="@/assets/img/icons/entity-stat-icons/avatar-person.svg"
                alt="icon"
              />
              <p
                class="namesty"
                v-if="
                  getPrimaryFields &&
                  getPrimaryFields.length &&
                  getPrimaryFields[0].inputType === 'DATE' &&
                  getPrimaryTitle
                "
              >
                {{ getPrimaryTitle | globalDateFormat }}
              </p>
              <p class="namesty" v-else>
                {{
                  getPrimaryTitle +
                  " " +
                  getEntityDataById.name +
                  " " +
                  "Profile"
                }}
              </p>
            </el-card>
          </el-col>
          <el-col
            :xl="{ span: 4 }"
            :lg="{ span: 4 }"
            :md="{ span: 12 }"
            :sm="{ span: 8 }"
            :xs="{ span: 8 }"
            :class="getIsMobile ? 'resStyle' : 'statstyle'"
            :style="
              this.$route.query.activeStep == 1
                ? 'background-color: #ff679d'
                : 'background-color: #ffdde9'
            "
          >
            <div
              :class="
                this.$route.query.activeStep == 1
                  ? 'textActiveStyle'
                  : 'textstatstyle'
              "
              v-on:click="handleStats(1)"
            >
              <p :style="getIsMobile ? 'font-size:10px' : ''">Entities Data</p>
              <img
                :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
                src="@/assets/img/icons/entity-stat-icons/IconEntitiesInactive.svg"
                alt="icon"
              />
            </div>
            <span
              v-if="
                this.getEntityDataById &&
                this.getEntityDataById.templates &&
                this.getEntityDataById.templates.length
              "
              v-on:click="handleStats(1)"
              style="cursor: pointer"
            >
              <p
                :class="
                  this.$route.query.activeStep == 1
                    ? 'number_sty_active fs-6 fw-light '
                    : 'number_sty fs-6 fw-light '
                "
              >
                {{ this.getEntityDataById.templates.length }}
              </p>
            </span>
          </el-col>
          <el-col
            :xl="{ span: 4 }"
            :lg="{ span: 4 }"
            :md="{ span: 12 }"
            :sm="{ span: 8 }"
            :xs="{ span: 8 }"
            :class="getIsMobile ? 'resStyle' : 'statstyle'"
            :style="
              this.$route.query.activeStep == 2
                ? 'background-color: #40c0f0'
                : 'background-color: #e8f9ff'
            "
          >
            <div
              :class="
                this.$route.query.activeStep == 2
                  ? 'textActiveStyle'
                  : 'textstatstyle'
              "
              v-on:click="handleStats(2)"
            >
              <p :style="getIsMobile ? 'font-size:10px' : ''">Documents</p>
              <img
                :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
                src="@/assets/img/icons/entity-stat-icons/DocumentsInactive.svg"
                alt="icon"
              />
            </div>
            <span
              v-if="this.templates && this.templates.length"
              style="cursor: pointer"
              v-on:click="handleStats(2)"
            >
              <p
                :class="
                  this.$route.query.activeStep == 2
                    ? 'number_sty_active fs-6 fw-light '
                    : 'number_sty fs-6 fw-light '
                "
              >
                {{ this.templatesCount }}
              </p>
            </span>
            <span v-else>
              <p
                :class="
                  this.$route.query.activeStep == 2
                    ? 'number_sty_active fs-6 fw-light '
                    : 'number_sty fs-6 fw-light '
                "
              >
                {{ 0 }}
              </p>
            </span>
          </el-col>
          <el-col
            :xl="{ span: 4 }"
            :lg="{ span: 4 }"
            :md="{ span: 12 }"
            :sm="{ span: 8 }"
            :xs="{ span: 8 }"
            :class="getIsMobile ? 'resStyle' : 'statstyle'"
            :style="
              this.$route.query.activeStep == 3
                ? 'background-color: #f2dd27'
                : 'background-color: #fffbd9'
            "
          >
            <div
              :class="
                this.$route.query.activeStep == 3
                  ? 'textActiveStyle'
                  : 'textstatstyle'
              "
              v-on:click="handleStats(3)"
            >
              <p :style="getIsMobile ? 'font-size:10px' : ''">Document Data</p>
              <img
                :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
                src="@/assets/img/icons/entity-stat-icons/DocumentDataInactive.svg"
                alt="icon"
              />
            </div>
            <span
              v-if="this.templates && this.templates.length"
              style="cursor: pointer"
              v-on:click="handleStats(3)"
            >
              <p
                :class="
                  this.$route.query.activeStep == 3
                    ? 'number_sty_active fs-6 fw-light '
                    : 'number_sty fs-6 fw-light '
                "
              >
                {{ this.templatesCount }}
              </p>
            </span>
            <span v-else v-on:click="handleStats(3)">
              <p
                :class="
                  this.$route.query.activeStep == 3
                    ? 'number_sty_active fs-6 fw-light '
                    : 'number_sty fs-6 fw-light '
                "
              >
                {{ 0 }}
              </p>
            </span>
          </el-col>
          <!-- <el-col
            :xl="{ span: 4 }"
            :lg="{ span: 4 }"
            :md="{ span: 12 }"
            :sm="{ span: 12 }"
            :xs="{ span: 12 }"
            :class="getIsMobile ? 'resStyle' : 'statstyle'"
            :style="
              this.$route.query.activeStep == 4
                ? 'background-color: #08ba73'
                : 'background-color: #edfcf6'
            "
          >
            <div
              :class="
                this.$route.query.activeStep == 4
                  ? 'textActiveStyle'
                  : 'textstatstyle'
              "
              v-on:click="handleStats(4)"
            >
              Data Workflows
              <img
                :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
                src="@/assets/img/icons/entity-stat-icons/DataWorkflowsInactive.svg"
                alt="icon"
              />
            </div>
            <span>
              <p
                :class="
                  this.$route.query.activeStep == 4
                    ? 'number_sty_active fs-6 fw-light '
                    : 'number_sty fs-6 fw-light '
                "
              >
                {{ 0 }}
              </p>
            </span>
          </el-col>-->
        </el-row>
        <template v-if="getAllSteps && getAllSteps.length && activeStep === 1">
          <div style="display: flex !important" v-if="getIsMobile">
            <el-button
              :icon="toggleMenu ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
              class="mt-1"
              @click="displayToggle"
            ></el-button>
            <div class="list-group-menu" v-if="toggleMenu && getIsMobile">
              <el-menu class="el-menu-vertical-demo sidemenu">
                <el-menu-item
                  v-if="
                    getEntityDataById &&
                    getEntityDataById.entity_type === 'BUSINESS' &&
                    checkIsFilteredData
                  "
                  index="2"
                  key="contacts"
                >
                  <router-link :to="getStepUrl({}, false, 'contacts')">
                    <img
                      class
                      src="@/assets/img/template-icons/block-portfolio.svg"
                      alt="icons"
                      width="20"
                    />
                    <span>Contacts</span>
                  </router-link>
                </el-menu-item>
                <el-menu-item
                  index="1"
                  v-for="(step, index) of getAllSteps"
                  :key="'templates-' + index"
                >
                  <router-link
                    :class="getClass(step)"
                    :to="getStepUrl(step, true, '')"
                  >
                    <el-tooltip
                      v-if="getIsMobile"
                      trigger="hover"
                      class="item"
                      effect="dark"
                      :content="step.templateInfo.name"
                      placement="top-start"
                    >
                      <img
                        class
                        v-if="step.templateInfo.icon"
                        :src="
                          require('@/assets/img/template-icons/' +
                            step.templateInfo.icon)
                        "
                        alt="icon"
                        width="15"
                      />

                      <img
                        v-else
                        class
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="15"
                      />
                    </el-tooltip>
                    <span style="color: #000000; font-size: 10px">{{
                      step.templateInfo.name | truncate(10, "...")
                    }}</span>
                  </router-link>
                </el-menu-item>
                <template v-if="checkIsFilteredData">
                  <el-menu-item
                    index="2"
                    v-for="(reationship, index) of relationshipsData"
                    :key="'reationships' + index"
                  >
                    <router-link
                      :class="getClass(reationship)"
                      :to="getStepEntityRelationshipUrl(reationship)"
                      v-if="checkIsFilteredData"
                    >
                      <img
                        class
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span>{{
                        reationship.relationship_title | truncate(25, "...")
                      }}</span>
                    </router-link>
                  </el-menu-item>
                </template>
                <hr />
              </el-menu>
            </div>
          </div>
          <el-row v-if="!getIsMobile">
            <el-col :lg="6" :md="8" :sm="6" :xs="24">
              <div class="sidebar-navigation topwidth">
                <div class="list-group">
                  <el-menu class="el-menu-vertical-demo sidemenu">
                    <el-menu-item
                      v-if="
                        getEntityDataById &&
                        getEntityDataById.entity_type === 'BUSINESS' &&
                        checkIsFilteredData
                      "
                      index="0"
                      key="contacts"
                    >
                      <router-link :to="getStepUrl({}, false, 'contacts')">
                        <img
                          style="margin-left: -5px"
                          class="mr-1"
                          src="@/assets/img/template-icons/block-portfolio.svg"
                          alt="icons"
                          width="20"
                        />
                        <span>Contacts</span>
                      </router-link>
                    </el-menu-item>

                    <el-menu-item
                      index="1"
                      v-for="(step, index) of getAllSteps"
                      :key="index"
                    >
                      <router-link
                        :class="getClass(step)"
                        :to="getStepUrl(step, true, '')"
                      >
                        <img
                          class="ml-2"
                          v-if="step.templateInfo && step.templateInfo.icon"
                          :src="
                            logoUploadUrl +
                            '/assets/img/template-icons/' +
                            step.templateInfo.icon
                          "
                          alt="icon"
                          width="20"
                        />
                        <img
                          class="ml-2"
                          v-else
                          :src="
                            logoUploadUrl +
                            '/assets/img/template-icons/block-portfolio.svg'
                          "
                          alt="icons"
                          width="20"
                        />
                        {{
                          step.senderAlias
                            ? step.senderAlias
                            : step.templateInfo.name | truncate(28, "...")
                        }}
                        <!-- <i :class="getCompletionStatusClass(step)"></i> -->
                      </router-link>
                    </el-menu-item>
                    <template v-if="checkIsFilteredData">
                      <el-menu-item
                        index="3"
                        v-for="(reationship, index) of relationshipsData"
                        :key="'reationships' + index"
                      >
                        <router-link
                          :class="getClass(reationship)"
                          :to="getStepEntityRelationshipUrl(reationship)"
                        >
                          <img
                            class="ml-2 mr-1"
                            src="@/assets/img/template-icons/block-portfolio.svg"
                            alt="icons"
                            width="20"
                          />
                          <span>
                            {{
                              reationship.relationship_title
                                | truncate(25, "...")
                            }}
                          </span>
                        </router-link>
                      </el-menu-item>
                    </template>
                    <hr />
                    <template v-if="checkIsFilteredData">
                      <el-menu-item
                        index="2"
                        v-for="(reationship, index) of nestedRelationshipsData"
                        :key="'nestedreationships' + index"
                      >
                        <router-link
                          :class="getClass(reationship)"
                          :to="getStepEntityRelationshipUrl(reationship)"
                        >
                          <img
                            class="ml-2 mr-1"
                            src="@/assets/img/template-icons/block-portfolio.svg"
                            alt="icons"
                            width="20"
                          />
                          <span>
                            {{
                              reationship.relationship_title
                                | truncate(25, "...")
                            }}
                          </span>
                        </router-link>
                      </el-menu-item>
                    </template>
                  </el-menu>
                </div>
              </div>
            </el-col>
            <el-col :lg="18" :md="16" :sm="18" :xs="24">
              <div class="main-content-block">
                <router-view
                  :layout.sync="layout"
                  :key="$route.fullPath"
                ></router-view>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="getIsMobile">
            <el-col v-if="!getIsMobile" :md="4" :sm="6" :xs="6"></el-col>
            <el-col v-if="!getIsMobile" :md="6" :sm="6" :xs="24">
              <!-- <el-scrollbar class="sidemenu"> -->
              <div class="list-group">
                <el-menu class="el-menu-vertical-demo sidemenu">
                  <el-menu-item
                    v-if="
                      getEntityDataById.isAccount &&
                      getEntityDataById.entity_type != 'INDIVIDUAL'
                    "
                    index="2"
                    key="contacts"
                  >
                    <router-link :to="getStepUrl({}, false, 'contacts')">
                      <img
                        style="margin-left: -5px"
                        class="mr-1"
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span>Contacts</span>
                    </router-link>
                  </el-menu-item>
                  <el-menu-item
                    index="1"
                    v-for="(step, index) of getAllSteps"
                    :key="'templates-' + index"
                  >
                    <router-link
                      :class="getClass(step)"
                      :to="getStepUrl(step, true, '')"
                    >
                      <img
                        class="ml-2 mr-1"
                        v-if="step.templateInfo.icon"
                        :src="
                          require('@/assets/img/template-icons/' +
                            step.templateInfo.icon)
                        "
                        alt="icon"
                        width="20"
                      />

                      <img
                        v-else
                        class="ml-2 mr-1"
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span>
                        {{ step.templateInfo.name | truncate(25, "...") }}
                      </span>
                    </router-link>
                  </el-menu-item>
                  <el-menu-item
                    index="2"
                    v-for="(reationship, index) of relationshipsData"
                    :key="'reationships' + index"
                  >
                    <router-link
                      :class="getClass(reationship)"
                      :to="getStepEntityRelationshipUrl(reationship)"
                    >
                      <img
                        class="ml-2 mr-1"
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span>
                        {{
                          reationship.relationship_title | truncate(25, "...")
                        }}
                      </span>
                    </router-link>
                  </el-menu-item>
                  <hr />
                </el-menu>
              </div>
              <!-- </el-scrollbar> -->
            </el-col>
            <el-col
              v-if="!getIsMobile"
              class="secondcol"
              :md="18"
              :sm="18"
              :xs="24"
            >
              <router-view
                :layout.sync="layout"
                :key="$route.fullPath"
              ></router-view>
            </el-col>
            <el-col
              v-if="getIsMobile"
              class="secondcol-res"
              :md="24"
              :sm="24"
              :xs="24"
            >
              <router-view
                :layout.sync="layout"
                :key="$route.fullPath"
              ></router-view>
            </el-col>
          </el-row>
        </template>
        <!-- ** entities block end ** -->
        <!-- *** entities document templates block start *** -->
        <template v-if="activeStep === 2">
          <EntityDocuments
            :entityId="entityId"
            :entityDataId="entityDataId"
            :isDocument="isDocument"
          />
        </template>
        <!-- *** entities document templates block end *** -->

        <!-- *** entities document data block start *** -->
        <template v-if="activeStep === 3">
          <EntityDocumentsData
            :entityId="entityId"
            :entityDataId="entityDataId"
          />
        </template>
        <!-- *** entities document data block end *** -->
        <!-- *** entities data workflows block start *** -->
        <template v-if="activeStep === 4">
          <EntityDataWorkflows
            :entityId="entityId"
            :entityDataId="entityDataId"
          />
        </template>
        <!-- *** entities data workflows block end *** -->
      </div>
    </div>

    <el-dialog
      title="Warning"
      :visible.sync="errorDialog.status"
      style="margin-top: 10%"
      :before-close="backToEntityInfoList"
      class="warning-popup"
    >
      <span>
        <h5>Something went wrong with this entity</h5>
      </span>
      <span>{{ errorDialog.message }}</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">Cancel</el-button> -->
        <el-button type="plain" @click="backToEntityInfoList()">Back</el-button>
        <el-button type="primary" @click="addTemplatesToEntity()"
          >Add Templates</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="Primary Data"
      :visible.sync="addPrimaryDataDialog"
      :before-close="backToEntityInfoList"
      class="primary-data-popup"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-scrollbar
        wrap-style="max-height: 400px;"
        v-loading="primaryDataAddLoading"
        :element-loading-text="primaryDataAddLoadingText"
      >
        <el-form
          ref="form"
          :model="form"
          v-if="getPrimaryFields && getPrimaryFields.length"
          @submit.native.prevent
        >
          <!-- {{ getEntityDataById.primaryFields }} -->

          <PrimaryDataForm
            :fieldsListData="getPrimaryFields"
            :form="form"
            :entityDataId="this.entityDataId"
            :parentyEntityId="entityId"
          ></PrimaryDataForm>
        </el-form>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="addPrimaryDataToEntity('form')"
          size="small"
        >
          <i class="el-icon-plus"></i> Add Primary Info
        </el-button>
        <el-button @click="backToEntityInfoList" size="small">Cancel</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import EntityHelper from "@/mixins/EntitiesHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";

import { makeSlug } from "../../helpers/appHelper";
import axios from "@/config/axios";

export default {
  name: "Entity-EntityExecute",
  components: {
    PrimaryDataForm: () => import("./PrimaryDataCollectionForm"),
  },
  data() {
    return {
      entityId: null,
      loading: false,
      step: 1,
      slug: null,
      activeStep: 1,
      layout: "div",
      templates: [],
      entityDataId: null,
      addPrimaryDataDialog: false,
      entityIconPath: "block-portfolio.svg",
      isEdit: false,
      form: {},
      errorDialog: {
        status: false,
        message: "",
      },
      relationshipsData: [],
      nestedRelationshipsData: [],
      loadingText: "fetching details...",
      primaryFields: [],
      allFields: [],
      entityAllFields: [],
      toggleMenu: false,
      primaryDataAddLoading: false,
      primaryDataAddLoadingText: "",
    };
  },
  mixins: [EntityHelper, NavigationHelper, entityRelationshipMixin],
  async mounted() {
    this.loading = true;
    if (this.$route.query.dataId) {
      this.entityDataId = this.$route.query.dataId;
      this.fetchEntityData(this.entityDataId);
    }
    if (this.$route.query?.filter) {
      await this.$store.dispatch(
        "filters/getFilterById",
        this.$route.query?.filter
      );
    }
    if (
      this.$route.name === "EntityDataEdit" ||
      this.$route.name === "EntityDataEditStep"
    ) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
    await this.getEntityData();
    this.getEntityPrimaryTitle();
    await this.fetchEntityRelationships();
    await this.fetchEntityNestedRelationships();

    setTimeout(() => {
      this.loading = false;
    }, 1500);
  },

  created() {
    bus.$on("next-step", (data) => {
      if (data && data.newEntityData && data.newEntityData._id) {
        this.entityDataId = data.newEntityData._id;
        this.setRelationshipDetails();
      }

      if (data && data.finish) {
        // we have to check entity relationship tabs here
        if (
          this.relationshipsData &&
          this.relationshipsData.length &&
          !this.$route.query?.filter
        ) {
          let url = this.getStepEntityRelationshipUrl(
            this.relationshipsData[0]
          );
          this.$router.push({ path: url });
        } else {
          this.backToEntityInfoList();
        }
      }

      if (data && data.firstStep) {
        this.gotoStep(this.getAllSteps[0]);
      } else {
        this.gotoNextStep();
      }
    });
  },
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getCompanyEntityDetails",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityNestedRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    getIndexFromStep() {
      return this.step - 1;
    },
    getStepDetails() {
      if (
        this.getEntityDataById &&
        this.getEntityDataById.templates &&
        this.getEntityDataById.templates
      ) {
        return this.getEntityDataById.templates[this.getIndexFromStep];
      }
      return {};
    },
    getAllSteps() {
      if (this.getEntityDataById && this.getEntityDataById.templates) {
        if (
          this.$route.query?.filter &&
          this.getSingleFilterData?.permissions?.templates &&
          this.getEntityDataById._id == this.getSingleFilterData.entity_id
        ) {
          return this.getEntityDataById.templates.filter(
            (e) =>
              this.getSingleFilterData?.permissions?.templates.indexOf(
                e.template_id
              ) != -1
          );
        }
        return this.getEntityDataById.templates;
      }
      return [];
    },
    getStepUrl() {
      return (step, status, key) => {
        let query = this.getNavigationQuery(this.$route.query);
        let additionalQuery = "";
        if (query.group) {
          let string = {
            group: query.group,
            routeType: query.routeType,
            key: query.key,
          };
          additionalQuery = "&" + new URLSearchParams(string).toString();
        }
        if (status) {
          let url =
            `/entityData-Edit/${this.$route.params.entity_id}/${
              step.templateInfo.slug
            }?dataId=${this.entityDataId || ""}` + additionalQuery;
          if (this.$route.query.filter) {
            url = url + "&filter=" + this.$route.query.filter;
          }
          if (this.$route.query.fromCustom) {
            url = url + "&fromCustom=" + this.$route.query.fromCustom;
          }
          return url;
        } else {
          let url =
            `/entityData-Edit/${this.$route.params.entity_id}/${key}?dataId=${
              this.entityDataId || ""
            }&templateKey=${key}` + additionalQuery;
          if (this.$route.query.filter) {
            url = url + "&filter=" + this.$route.query.filter;
          }
          if (this.$route.query.fromCustom) {
            url = url + "&fromCustom=" + this.$route.query.fromCustom;
          }
          return url;
        }
      };
    },
    getPrimaryFields() {
      if (this.getEntityDataById && this.getEntityDataById.primaryFields) {
        return this.entityAllFields.filter(
          (e) =>
            this.getEntityDataById.primaryFields
              .flatMap((e) => e.key)
              .indexOf(e.key) !== -1
        );
      }
      return [];
    },

    getStepEntityRelationshipUrl() {
      return (reationship) => {
        let slug = reationship.relationship_slug;
        let id = reationship.relationship_id;
        let url = `/entityData-Edit/${
          this.$route.params.entity_id
        }/${slug}?dataId=${this.entityDataId || ""}&relationship_id=${id}`;
        if (this.$route.query.filter) {
          url = url + `&filter=${this.$route.query.filter}`;
        }
        if (this.$route.query.fromCustom) {
          url = url + "&fromCustom=" + this.$route.query.fromCustom;
        }
        return url;
      };
    },
    getStepContcatsUrl() {
      return (key) => {
        let url = `/entityData-Edit/${this.$route.params.entity_id.toString()}/${key}?dataId=${
          this.entityDataId ? this.entityDataId : ""
        }&templateKey=${key}`;
        if (this.$route.query.filter) {
          url = url + `&filter=${this.$route.query.filter}`;
        }
        return url;
      };
    },
    getClass() {
      return (step) => {
        if (
          this.$route.params.template_code == step.relationship_slug ||
          (step.templateInfo &&
            this.$route.params.template_code == step.templateInfo.slug)
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
    activeStepp() {
      return (step) => {
        if (this.$route.params.template_code === step.slug) {
          return true;
        }
        return false;
      };
    },
    getCompletionStatusClass() {
      return (step) => {
        console.log(step);
        //     return `el-icon-success step-success`;
        return `el-icon-circle-check step-default`;
      };
    },
    checkIsFilteredData() {
      if (
        this.$route.query?.filter &&
        this.getSingleFilterData &&
        this.getEntityDataById &&
        this.getEntityDataById._id == this.getSingleFilterData.entity_id
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    checkPerimission(access) {
      if (
        this.$route.query?.filter &&
        this.getSingleFilterData &&
        this.getEntityDataById &&
        this.getEntityDataById._id == this.getSingleFilterData.entity_id &&
        this.getSingleFilterData?.permissions?.access_set
      ) {
        if (!this.getSingleFilterData.permissions.access_set.length) {
          return true;
        }
        return (
          this.getSingleFilterData.permissions.access_set.indexOf(access) === -1
        );
      }
      return false;
    },
    displayToggle() {
      this.toggleMenu = !this.toggleMenu;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    async getEntityData() {
      this.loading = true;
      this.entityId = this.$route.params.entity_id;
      await this.$store.dispatch("entities/fetchEntityById", {
        entity_id: this.entityId,
      });
      this.$store.commit(
        "templatesData/setTemplatesPrimaryData",
        {},
        {
          root: true,
        }
      );
      if (
        this.getPrimaryFields &&
        this.getPrimaryFields.length &&
        this.entityDataId
      ) {
        await this.$store.dispatch(
          "templatesData/fetchUserTemplatesPrimaryData",
          {
            entity_data_id: this.entityDataId,
            template_id: this.getPrimaryFields[0].template_id,
          }
        );
      }
      if (this.getAllSteps && this.getAllSteps.length) {
        if (this.getEntityDataById) {
          if (
            this.getEntityDataById &&
            this.getEntityDataById.templates &&
            this.getEntityDataById.templates.length
          ) {
            this.allFields = [];
            this.entityAllFields = [];
            this.getEntityDataById.templates.forEach((e) => {
              if (e && e.templateInfo && !e.is_repeatable) {
                this.allFields = [
                  ...this.allFields,
                  ...this.getTemplateFields(e.templateInfo),
                ];
                this.entityAllFields = [
                  ...this.entityAllFields,
                  ...this.getTemplateFields(e.templateInfo),
                ];
              }
            });
            this.allFields = this.allFields.flatMap((e) => e.key);
            if (
              this.getEntityDataById.primaryFields &&
              this.getEntityDataById.primaryFields.length
            ) {
              let primaryFields = this.getEntityDataById.primaryFields.filter(
                (e) => this.allFields.indexOf(e.key) != -1
              );
              if (primaryFields && primaryFields.length) {
                this.errorDialog = {
                  status: false,
                  message: ``,
                };
                if (
                  !this.isEdit &&
                  this.getEntityDataById &&
                  this.getEntityDataById.entity_type != "INDIVIDUAL"
                ) {
                  this.addPrimaryDataDialog = true;
                  bus.$emit("primaryData", {
                    addPrimaryDataDialog: this.addPrimaryDataDialog,
                  });
                }
                this.gotoStep(this.getAllSteps[0]);
              } else {
                this.errorDialog = {
                  status: true,
                  message: `${this.getEntityDataById.name} entity does not have primary fields.`,
                };
              }
            } else {
              this.errorDialog = {
                status: true,
                message: `${this.getEntityDataById.name} entity does not have primary fields.`,
              };
            }
          } else {
            this.errorDialog = {
              status: true,
              message: `${this.getEntityDataById.name} entity does not have templates.`,
            };
          }
        }
      } else {
        this.errorDialog.status = true;
        this.errorDialog = {
          status: true,
          message: `${this.getEntityDataById.name} entity does not have templates.`,
        };
      }
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    async gotoStep(step) {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: this.getStepUrl(step, true, ""),
        query: query,
      });
    },
    gotoNextStep() {
      const code = this.$route.params.template_code;

      const currentStepIndex = this.getAllSteps.findIndex(
        (x) => x.templateInfo.slug == code
      );
      if (
        currentStepIndex > -1 &&
        currentStepIndex < this.getAllSteps.length - 1 &&
        !this.loading
      ) {
        this.gotoStep(this.getAllSteps[currentStepIndex + 1]);
      }
    },
    async addTemplatesToEntity() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "Edit Entity",
        params: {
          entity_id: this.$route.params.entity_id,
        },
        query: query,
      });
    },
    async backToEntityInfoList() {
      this.loading = true;
      this.addPrimaryDataDialog = false;
      this.errorDialog = {
        status: false,
        message: "",
      };

      setTimeout(() => {
        this.loading = false;
      }, 1000);
      if (this.$route.query?.fromCustom) {
        this.$router.go(-2);
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          path: "/entity/" + this.entityId,
          query: query,
        });
      }
    },
    getRequired() {
      return this.getPrimaryFields.filter(
        (e) => this.form[e.key] || typeof this.form[e.key] === "number"
      ).length;
    },
    async handleStats(val) {
      if (this.entityDataId) {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          path: `/entity_data_view/${this.$route.params.entity_id}/${this.$route.params.template_code}`,
          // ?dataId=${this.entityDataId ? this.entityDataId : ""}&activeStep=${
          //   val ? val : 1
          query: {
            ...query,
            dataId: this.entityDataId ? this.entityDataId : "",
            activeStep: val ? val : 1,
          },
        });
      } else {
        this.$message.warning("Please fill and save data!");
      }
    },
    getTotalCount() {
      return this.getPrimaryFields.filter((e) => e.key).length;
    },
    async addPrimaryDataToEntity() {
      try {
        this.loading = true;
        this.primaryFields = [...this.getPrimaryFields];
        // let incrementFields = this.primaryFields.filter(
        //   (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
        // );

        // if (incrementFields) {
        //   let temp_id = incrementFields[0]?.template_id;
        //   if (incrementFields && incrementFields.length) {
        //     let data = await this.autoIncrement(temp_id);
        //     await incrementFields.map(async (field) => {
        //       this.form[field.key] =
        //         data[field.key] && data[field.key].value
        //           ? data[field.key].value
        //           : "";
        //       this.form[`${field.key}_info`] =
        //         data[field.key] && data[field.key].split
        //           ? data[field.key].split
        //           : {};
        //     });
        //   }
        // }
        if (this.form && this.getRequired() === this.getTotalCount()) {
          //  if(this.getEntityDataById.primaryFields === 'PHONE_COUNTRY_CODE'){
          //    this.$set(this.form,'countryCallingCode',data.countryCallingCode);
          //  }

          this.primaryFields = [...this.getPrimaryFields];

          this.primaryFields.forEach((e) => {
            if (
              e.input_type == "ENTITY" &&
              e.entity_id &&
              this.form[e.key] &&
              this.form[e.key + "/name"]
            ) {
              e["entity_data_key"] = e.key + "/name";
            }
          });
          await this.checkForDuplicateData();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }

        setTimeout(() => {
          this.loading = false;
        }, 1500);
      } catch (err) {
        console.log("setTimeout",err);
      }
    },
    async checkForDuplicateData() {
      this.primaryDataAddLoading = true;
      this.primaryDataAddLoadingText = "Checking duplicate data...";
      if (this.hasRepeatableTemplate) {
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        await this.createEntityData();
        return;
      }
      let templateData = [];
      this.primaryFields.forEach((pf) => {
        let findIndex = templateData.findIndex(
          (e) => e.template_id == pf.template_id
        );
        if (findIndex > -1) {
          let existedData = templateData[findIndex];
          existedData.form = {
            ...existedData.form,
            ...{ [pf.key]: this.form[pf.key] },
          };
          templateData[findIndex] = { ...existedData };
        } else {
          templateData.push({
            template_id: pf.template_id,
            form: { [pf.key]: this.form[pf.key] },
          });
        }
      });
      let params = {
        entity_id: this.$route.params.entity_id,
        parent_entityDataId: null,
        data: templateData,
      };
      await this.$store.dispatch("templatesData/checkDuplicateData", params);
      if (this.getDuplicateDataCheck) {
        if (!this.getDuplicateDataCheck?.allowed) {
          this.$notify.warning({
            title: "Warning",
            message:
              "With this data already " +
              (this.getEntityDataById && this.getEntityDataById.name
                ? this.getEntityDataById.name + " is"
                : "data is") +
              " created.",
          });
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          return;
        }
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.createEntityData();
      } else {
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.$message({
          message: "Error while checking data",
          type: "error",
        });
      }
    },
    async createEntityData() {
      this.primaryDataAddLoading = true;
      this.primaryDataAddLoadingText = "Creating new entity data...";
      let data = {
        primaryFields: this.primaryFields,
        formData: this.form,
        templates: this.getEntityDataById.templates,
        entity_id: this.$route.params.entity_id,
      };
      await this.$store.dispatch("templatesData/createEntityPrimaryData", data);
      if (
        this.getPrimaryEntityDataStatus.message ===
        "Primary fields data required"
      ) {
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.$notify.error({
          title: "Error",
          message:
            "This form contains unique field.Cannot not create duplicate value",
        });
      } else {
        if (this.getPrimaryEntityDataStatus) {
          this.addPrimaryDataDialog = false;
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          this.$notify.success({
            title: "Success",
            message: "Primary Data saved successfully",
          });
          if (this.getNewEntityData) {
            this.fetchEntityData(this.getNewEntityData._id);
            if (
              this.getCurrentEntityRelationships &&
              this.getCurrentEntityRelationships.length
            ) {
              this.entityDataId = this.getNewEntityData._id;
              this.setRelationshipDetails();
              await this.getEntityPrimaryTitleFromNew();
              //await this.updateEntitiesData();
            }
            bus.$emit("next-step", {
              newEntityData: this.getNewEntityData,
              firstStep: true,
            });
          }
        } else {
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          this.$notify.error({
            title: "Error",
            message: "Error at saving data",
          });
        }
      }
    },
    async autoIncrement(temp_id) {
      try
      {
      let params = {
        entity_id: this.entityId,
        template_id: temp_id,
        // entity_data_id: this.entityDataId,
        // template_id: this.templateData._id,
      };
      const response = await axios.get("get-form-template-invoice-info", {
                params
            })
            return response.data.data;
            // if(response.data && response.data.success=="true")
            // {
            //   return response.data.data
            // }
            // else{
            //   return {}
            // }
      // await this.$store.dispatch("templatesData/fetchinvoiceinfo", params);
      // if (this.getformtemplateinvoiceinfo) {
      //   return this.getformtemplateinvoiceinfo;
      // } else {
      //   return {};
      // }
    }
    catch(error)
    {
      console.log("autoIncrement",error);
      return {}
    }
    },
    async fetchEntityRelationships() {
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        { entity_id: this.entityId }
      );
      if (this.getEntityRelationships && this.getEntityRelationships.length) {
        this.setRelationshipDetails();
      }

      this.loading = false;
    },

    async updateEntitiesData() {
      // ** mixin method ** //
      let toAssignRelationshipsData = this.prepareRelationshipData(
        this.getEntityDataById,
        this.getCurrentEntityRelationships,
        this.entityDataId
      );

      if (toAssignRelationshipsData.length) {
        await this.$store.dispatch(
          "entityRelationships/assignFieldsEntityRelationshipData",
          toAssignRelationshipsData
        );
      }
    },

    setRelationshipDetails() {
      this.relationshipsData = [...(this.getEntityRelationships || [])];

      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityRelationships",
        this.relationshipsData
      );
      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
    },

    async fetchEntityNestedRelationships() {
      this.loading = true;
      this.loadingText = "Fetching  Nested Entity relationships...";
      await this.$store.dispatch(
        "entityRelationships/fetchEntityNestedRelationships",
        { entity_id: this.entityId }
      );

      this.nestedRelationshipsData = [
        ...(this.getEntityNestedRelationships || []),
      ];
      // we have to add type owner of relationship
      this.nestedRelationshipsData = this.nestedRelationshipsData.map((e) => {
        e.owner_type = "PARENT";
        e.relationship_title =
          e.parent_relationship_details.relationship_title +
          "/" +
          e.child_relation_title;
        e.relationship_slug =
          e.parent_relationship_details.relationship_slug +
          "-" +
          makeSlug(e.relationship_title);
        e.relationship_id = e.child_entity_id;
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityNestedRelationships",
        this.nestedRelationshipsData
      );

      this.loading = false;
    },
  },
  watch: {
    "$route.query.step"(step) {
      this.step = step;
    },
    "$route.params.key"(key) {
      this.key = key;
    },
    "$route.params.slug"(slug) {
      this.slug = slug;
    },
    "$route.query.activeStep"(step) {
      this.activeStep = parseInt(step) ? parseInt(step) : 1;
    },
  },
  beforeDestroy() {
    //   this.$store.commit("entities/setEntityDataById",null,{root:true})
    //   this.$store.commit("templatesData/setNewEntityData",null,{root:true})
    //   this.$store.commit("templatesData/setPrimaryEntityDataStatus",null,{root:true})
    bus.$off("next-step");
  },
};
</script>

<style lang="scss" scoped>
.topwidth {
  margin-top: 30px;
}
.statsSty {
  margin-top: -10px;
}
.entity-form-execute {
  margin-top: -30px;
  margin-right: -30px;
  .main-content-block {
    padding: 0px;
    margin-top: 5px;
    background-color: #ffffff;

    ::v-deep .preview-template-view {
      .preview-form-header {
        margin-bottom: 1.5em !important;
      }
      .footer-action-block {
        .el-button {
          margin: 0em 0 0 auto;
        }
      }
      .form-group {
        label {
          color: #5e6d82 !important;
        }
        p {
          margin-top: 0px;
        }
      }
    }
  }
  .sidebar-navigation {
    position: sticky;
    top: 100px;
    .page-title {
      padding: 10px 10px;
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 0px;
      .scrolling-text {
        position: relative;
        width: 230px;
        min-height: 25px;
        overflow: hidden;
        line-height: 1.6;
        padding: 0px;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 20px;
          background-image: linear-gradient(
            to right,
            #f8f9fa00,
            #f8f9fa05,
            #f8f9fa
          );
        }
        span {
          position: absolute;
          white-space: nowrap;
          transform: translateX(0);
          transition: 1.5s;
        }
        &:hover span {
          transform: translateX(calc(250px - 110%));
        }
      }
    }
    .templates-list {
      // margin-top: 2em;
      margin-left: 20px;
      .nav-link {
        a {
          position: relative;
          color: #999a9d;
          display: flex;
          align-items: center;
          padding: 1em 1em 1em 2em;
          margin-bottom: 0.5em;
          border-radius: 3px 0 0 3px;
          & * {
            vertical-align: middle;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            width: 10px;
            background-image: url("../../assets/img/icons/hover-dots.svg");
            background-size: 7px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: 0.3s all ease-in-out;
            -webkit-transition: 0.3s all ease-in-out;
          }
          &:hover:after {
            opacity: 1;
          }
          &:hover {
            color: #1b487e;
          }
          .step-edit,
          .step-unfill,
          .step-default,
          .step-success {
            position: absolute;
            top: 19px;
            right: 15px;
            font-size: 1.25em;
            padding-top: 3px;
          }
          .step-success {
            color: rgb(103, 206, 0);
          }

          .step-unfill {
            color: rgb(214, 214, 52);
          }

          .step-edit {
            color: blue;
          }

          .step-default {
            color: #999999;
          }
        }
        .router-link-active {
          color: #1b487e;
          background-color: #ffffff;
          box-shadow: -5px 5px 10px #f9f9f9;
          &::after {
            opacity: 1;
          }

          .step-success {
            color: rgb(103, 206, 0);
          }
        }
      }
    }
  }

  .el-dialog__body {
    min-height: 2rem !important;
    max-height: 4rem !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}
.list-group-menu {
  // margin-top: 35px;
  // border-bottom: 1px ridge;
  position: absolute;
  margin-top: 50px;
  z-index: 99;
  width: 150px;
  .list-group-item {
    margin-right: -20px;
    margin-left: -20px;
    padding: 0em;
    border: none;
    color: #fdfdfd;
    &.active {
      color: #f754a2;
      border-left: 3px solid #f754a2;
    }
  }
}
.sidemenu {
  height: 500px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.list-group {
  margin-top: 15px;
  width: 270px;
  border-bottom: 1px ridge;
  .list-group-item {
    margin-right: -20px;
    margin-left: -20px;
    padding: 0em;
    border: none;
    color: #9299b8;
    &.active {
      color: #f754a2;
      border-left: 3px solid #f754a2;
    }
  }
}
</style>
<style lang="scss">
.warning-popup {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;
    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }
    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }
    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }
    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }
    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }
  }
}
.primary-data-popup {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;
    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }
    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }
    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }
    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }
    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }
  }
}
.entity-form-execute {
  .template-body-section {
    .form-builder {
      .form-fields-holder {
        @media (max-width: 991.98px) {
          & > div {
            position: relative !important;
            left: 10px !important;
            top: 0px !important;
            margin-top: 30px;
            width: calc(100% - 20px) !important;
            input {
              width: 100%;
            }
            .heading {
              margin-top: 40px !important;
            }
            p {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
.iconStyle {
  width: 35px;
  margin-left: 110px;
  margin-top: -19px;
  margin-bottom: 10px;
}
.iconResStyle {
  width: 25px;
  margin-left: 50px;
  margin-top: -12px;
  margin-bottom: 10px;
}
.activeStyle {
  width: 35px;
  margin-left: 110px;
  margin-top: -9px;
  margin-bottom: 10px;
}
.textstatstyle {
  cursor: pointer;
  margin-top: 8px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #525f67;
}
.textActiveStyle {
  cursor: pointer;
  margin-top: 8px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}
.number_sty {
  color: black;
  margin-top: -43px;
  margin-left: 12px;
}
.number_sty_active {
  color: #ffffff;
  margin-top: -43px;
  margin-left: 12px;
}
.statstyle {
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 10px;
  width: 180px;
  height: 65px;
}
.resStyle {
  margin-top: 19px;
  margin-left: 20px;
  border-radius: 10px;
  width: 100px;
  height: 55px;
}
.imgsty {
  width: 35px;
  height: 35px;
}
.namesty {
  margin-top: -35px;
  margin-left: 50px;
}
</style>
